/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';
import {environment} from "../../environments/environment";
import {ProfileIntegrationPayload} from "../model/profile-integration-payload";
import {DecisionPayload} from "../model/decision-payload-ns";
import {FactView} from "../model/fact-view";
import {RequestView} from "../model/request-view";
import {Requests} from "../model/request-payload";
import {DataService} from "../data.service";


@Injectable({
    providedIn: 'root'
})
export class RequestCommandControllerService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    protected basePath = environment.resourceApiUri;

    constructor(protected dataService: DataService, protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * Make a request. Either for a claim subscription, claim proposal or the deletion of a fact.
     * @param topicId
     * @param makeRequestRequest
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public makeRequest(username: string, subscriptionPayload?: Requests.RequestPayload, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<RequestView>;

    public makeRequest(username: string, subscriptionPayload?: Requests.RequestPayload, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpResponse<RequestView>>;

    public makeRequest(username: string, subscriptionPayload?: Requests.RequestPayload, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpEvent<RequestView>>;

    public makeRequest(username: string, subscriptionPayload?: Requests.RequestPayload, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        localVarHeaders = localVarHeaders.set('Content-Type', 'application/vnd.doatoa.claim-subscription+json');

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/identities/${username}/requests/`;
        return this.httpClient.request<RequestView>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: subscriptionPayload,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public alterRequest(username: string, requestId?: string, type?: RequestView.TypeEnum, payload?: Requests.RequestPayload, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any>;

    public alterRequest(username: string, requestId?: string, type?: RequestView.TypeEnum, payload?: Requests.RequestPayload, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpResponse<RequestView>>;

    public alterRequest(username: string, requestId?: string, type?: RequestView.TypeEnum, payload?: Requests.RequestPayload, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpEvent<any>>;

    public alterRequest(username: string, requestId?: string, type?: RequestView.TypeEnum, payload?: Requests.RequestPayload, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        switch (type) {
            case RequestView.TypeEnum.proposedClaim:
                localVarHeaders = localVarHeaders.set('Content-Type', 'application/vnd.doatoa.my-identity-reference-integration+json');
                break;
            case RequestView.TypeEnum.subscription:
                localVarHeaders = localVarHeaders.set('Content-Type', 'application/vnd.doatoa.claim-subscription+json');
        }
        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/identities/requests/` + requestId;
        return this.httpClient.request<RequestView>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: payload,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public requestProfileIntegration(username: string, profileIntegrationPayload?: ProfileIntegrationPayload, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<RequestView>;

    public requestProfileIntegration(username: string, profileIntegrationPayload?: ProfileIntegrationPayload, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpResponse<RequestView>>;

    public requestProfileIntegration(username: string, profileIntegrationPayload?: ProfileIntegrationPayload, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpEvent<RequestView>>;

    public requestProfileIntegration(username: string, profileIntegrationPayload?: ProfileIntegrationPayload, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        localVarHeaders = localVarHeaders.set('Content-Type', 'application/vnd.doatoa.claim-proposal+json');

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/identities/${username}/requests/`;
        return this.httpClient.request<RequestView>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: profileIntegrationPayload,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Alter or process the request matching the given requestId.
     * This endpoint is a dynamic endpoint which can be used to alter my-requests (only for requestor) or process them (only for the requestee).
     * @param requestId The id of the request you wish to alter/process.
     * @param processRequestRequest
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processRequest(requestId: string, decisionPayload: DecisionPayload, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<RequestView>;

    public processRequest(requestId: string, decisionPayload: DecisionPayload, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<HttpResponse<RequestView>>;

    public processRequest(requestId: string, decisionPayload: DecisionPayload, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<HttpEvent<RequestView>>;

    public processRequest(requestId: string, decisionPayload: DecisionPayload, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<any> {
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling processRequest.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        const httpContentTypeSelected: string | undefined = 'application/vnd.doatoa.decision+json'
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        const username = this.dataService.get('username')!
        let localVarPath = `/identities/requests/${requestId}`;
        return this.httpClient.request<FactView>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: decisionPayload,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the subscription matching the given subscriptionId, can only be done by the owner of the topic or the subscriber.
     * @param requestId The id of the subscription you wish to remove.
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public delete(requestId: string, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any>;

    public delete(requestId: string, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpResponse<any>>;

    public delete(requestId: string, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpEvent<any>>;

    public delete(requestId: string, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any> {
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling unsubscribeFrom.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }
        const username = this.dataService.get('username')!
        let localVarPath = `/identities/requests/${requestId}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                    throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

}
