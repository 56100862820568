import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './registered-landing.component.html',
  styleUrl: './registered-landing.component.scss'
})
export class RegisteredLandingComponent {

  @Input() message: string = ''
  @Input() loading: boolean = false
  @Input() errorOccurred: boolean = false
}
