import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
    OauthCommandControllerService
} from "../../../../../doatoa-common/services/api/oauth-command-controller-service";

@Component({
    selector: 'app-consent-page',
    templateUrl: './consent-page.html',
    styleUrl: './consent-page.scss'
})
export class ConsentPage implements OnInit {

    consentedLoading: boolean = false
    deniedLoading: boolean = false
    state: string = ''
    nonce?: string;
    scope?: string;
    responseType?: string;
    clientName?: string;
    scopes?: Array<string> = []

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private oauthCommandControllerService: OauthCommandControllerService) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            this.state = p.params.state
            this.scopes = p.params.scopes
            this.clientName = p.params.client_name
        })
    }

    submitConsent(consent: boolean) {
        if (consent)
            this.consentedLoading = true
        else this.deniedLoading = true
        this.oauthCommandControllerService.process(consent, this.state)
            .subscribe({
                next: (response) => {
                    this.consentedLoading = false
                    this.deniedLoading = false
                    if (!consent) {
                        this.router.navigate(['/sign-in']).catch((e) => console.log(e))
                    }
                    window.location.href = response.redirectUri!
                },
                error: (error) => {
                    this.consentedLoading = false
                    this.deniedLoading = false
                }
            });
    }
}
