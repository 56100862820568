import {NgModule} from '@angular/core';
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {SignUpComponent} from "./sign-up.component";
import {PasswordStrengthMeterComponent} from "angular-password-strength-meter";

@NgModule({
    imports: [
        CommonDoaToaModule,
        PasswordStrengthMeterComponent
    ],
    declarations: [SignUpComponent]
})
export class SignUpModule { }