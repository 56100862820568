import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {IdentityCommandControllerService} from "../../../../../../doatoa-common/services";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    submitted = false;
    loading: boolean = false;
    username?: string = undefined;
    errorOccurred: boolean = false

    constructor(private clientCommandControllerService: IdentityCommandControllerService, private router: Router) {
    }

    ngOnInit(): void {
    }

    requestPasswordReset() {
        this.submitted = true
        setTimeout(() => {
            this.submitted = false
        }, 3000)
        if (!!this.username) {
            this.loading = true
        this.clientCommandControllerService.requestPasswordReset(this.username)
            .subscribe({
                next: (response) => {
                    this.router.navigate(['/password-landing'])
                    this.loading = false
                    this.username = undefined
                },
                error: (error) => {
                    this.loading = false
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
        }
    }

}
