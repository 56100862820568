import {NgModule} from '@angular/core';
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {SignInComponent} from "./sign-in.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";

@NgModule({
    imports: [
        CommonDoaToaModule,
    ],
    declarations: [SignInComponent, ResetPasswordComponent]
})
export class SignInModule { }