import {NgModule} from '@angular/core';
import {ChangePasswordComponent} from "./change-password.component";
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {PasswordStrengthMeterComponent} from "angular-password-strength-meter";

@NgModule({
    imports: [
        CommonDoaToaModule,
        PasswordStrengthMeterComponent
    ],
    declarations: [ChangePasswordComponent]
})
export class ChangePasswordModule { }