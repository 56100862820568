// app-routing.module.ts
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SignInComponent} from '../modules/sign-in/sign-in.component';
import {SignUpComponent} from "../modules/sign-up/sign-up.component";
import {RegisteredLandingComponent} from "../modules/landing/registered-landing.component";
import {ErrorComponent} from "../modules/error/error.component";
import {ConsentPage} from "../modules/consent-page/consent-page";
import {ChangePasswordComponent} from "../modules/change-password/change-password.component";
import {SuccessLandingComponent} from "../modules/landing/success-landing.component";
import {PasswordLandingComponent} from "../modules/landing/password-landing.component";
import {ActivateUserInitComponent} from "../modules/landing/activate-user-init.component";
import {MaintenanceComponent} from "../modules/landing/maintenance/maintenance.component";
import {ResetPasswordComponent} from "../modules/sign-in/reset-password/reset-password.component";
import {ExtensionLandingComponent} from "../modules/landing/extension-landing/extension-landing.component";

const routes: Routes = [
    {path: '', redirectTo: '/sign-in', pathMatch: 'full'},
    {path: 'extension', component: ExtensionLandingComponent},
    {path: 'sign-in', component: SignInComponent},
    {path: 'activate', component: ActivateUserInitComponent},
    {path: 'change-password', component: ChangePasswordComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'password-landing', component: PasswordLandingComponent},
    {path: 'consent', component: ConsentPage},
    {path: 'reset-password', component: ResetPasswordComponent},
    {path: 'sign-up', component: SignUpComponent},
    {path: 'registered-landing', component: RegisteredLandingComponent},
    {path: 'maintenance', component: MaintenanceComponent},
    {path: 'success-landing', component: SuccessLandingComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
