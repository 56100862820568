import {Injectable} from '@angular/core';
import pkceChallenge from "pkce-challenge";

@Injectable({
    providedIn: 'root'
})
export class CodeChallengeService {

    async createCodeChallenge(): Promise<CodeChallenge> {
        return await pkceChallenge(128);
    }
}

export interface CodeChallenge {
    code_verifier: string,
    code_challenge: string,
}
