<div class="container">
    <div *ngIf="!errorOccurred" class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'../../../assets/img/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="card max-width-400 text-center">
            <div class="p-2">
                <p>Password reset was successfully requested.</p>
                <p>Please follow the instructions in the e-mail you'll receive shortly.</p>
                <p>This page can now be closed safely.</p>
            </div>
        </div>
    </div>
    <app-error-modal [showModal]="errorOccurred" [message]="message"></app-error-modal>
</div>