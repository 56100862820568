import {ConfigurationParameters} from "../services";

export const environment = {
    production: true,
    resourceApiUri: 'https://dev.api.doatoa.io',
    idpApiUri: 'https://dev.idp.doatoa.io',
    resourceWebappUri: 'https://dev.app.doatoa.io',
    idpWebappUri: 'https://dev.auth.doatoa.io',
    resourceWebappClientId: 'dbf3007a-60d3-48c6-83cc-9d214119af24',
    idpExtensionClientId: '59284a9d-7c21-4c20-b1cb-0ed90a279d92',
    idpWebappClientId: '345872a9-bc8d-4411-a7bb-abe2f43a7c63',
    configurationParameters: new class implements ConfigurationParameters {
        withCredentials = true
    }
};
