<div class="container">
    <form (submit)="requestPasswordReset()"
          id="password-reset"
          class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'../../../assets/img/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="col-auto card max-width-300 p-3">
            <h2 class="text-center">Reset password</h2>
            <div>
                <input [(ngModel)]="username"
                       class="rounded-5 form-control {{ submitted && username == undefined || username == '' ? 'is-invalid': ''}}"
                       (keyup.enter)="requestPasswordReset()"
                       datatype="text"
                       id="username"
                       name="username"
                       placeholder="Enter your username"
                       type="text">
            </div>
            <div class="mb-3"></div>
            <app-generic-button
                    [widthClass]="''"
                    [type]="'submit'"
                    [disabled]="loading"
                    [loading]="loading"
                    [buttonText]="'Request password reset'"
                    [icon]="'fa-solid fa-unlock-keyhole'"></app-generic-button>
        </div>
    </form>
    <div class="m-3"></div>
    <div class="row justify-content-center">
        <div class="max-width-300 card text-center">
            <div class="p-2">Or
                <a class="secondary-blue"
                   routerLink="/sign-in">Sign in
                </a>
            </div>
        </div>
    </div>
</div>