<div class="container card p-2 mt-4 mb-4 max-width-1000">
    <app-logo [classes]="'mx-auto d-block mt-2'"
              [height]="'200'"
              [image]="'../../../assets/img/doatoa-logo-full.svg'"
              [width]="'300'"></app-logo>
    <div class="row justify-content-center mb-3">
        <h3 class="col-md-auto text-center"
            ><span class="secondary-blue">{{ clientName }}</span> would like access to the following resource(s):</h3>
    </div>
    <ul class="justify-content-center list-unstyled" *ngFor="let scope of scopes">
        <li class="text-center">{{scope | toReadableString}}</li>
    </ul>
    <div class="row justify-content-center">
        <h4 class="text-center">I hereby give <span class="secondary-blue">{{ clientName }}</span> permission to access this/these resource(s).</h4>
    </div>
    <div class="row justify-content-center">
        <app-generic-button (click)="submitConsent(true)"
                            class="col-md-auto m-3"
                            [id]="'consent-button-true'"
                            [loading]="consentedLoading"
                            [disabled]="deniedLoading"
                            [buttonText]="'Yes, I do'"
                            [btnClass]="'btn-basic-info'"
                            [icon]="'fa-solid fa-check'">
        </app-generic-button>
        <app-generic-button (click)="submitConsent(false)"
                            class="col-md-auto m-3"
                            [id]="'consent-button-false'"
                            [loading]="deniedLoading"
                            [disabled]="consentedLoading"
                            [buttonText]="'No, I don\'t'"
                            [btnClass]="'btn-primary-danger'"
                            [icon]="'fa-solid fa-xmark'">
        </app-generic-button>
    </div>
</div>
