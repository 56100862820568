// pkce.service.ts
import {Injectable} from '@angular/core';
import {CodeChallenge, CodeChallengeService} from "./code-challenge-service";

@Injectable({
    providedIn: 'root'
})
export class PKCEService {

    constructor(private codeChallengeService: CodeChallengeService) {
    }

    createCodeChallenge(): Promise<CodeChallenge> {
        return this.codeChallengeService.createCodeChallenge()
    }

}
