import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {provideZxvbnServiceForPSM, ZxvbnConfigType} from "angular-password-strength-meter/zxcvbn";
import {translations} from '@zxcvbn-ts/language-en';
import {DataService} from "../../../doatoa-common/services/data.service";
import {RequestAuthorizationInterceptor} from "../../../doatoa-common/services/request-authorization-interceptor.service";
import {CacheInterceptor} from "../../../doatoa-common/services/cache-interceptor";
import {TextFieldModule} from "@angular/cdk/text-field";
import {ResponseInterceptor} from "../../../doatoa-common/services/response-interceptor";
import {ErrorModule} from "./modules/error/error.module";
import {LandingModule} from "./modules/landing/landing.module";
import {SignUpModule} from "./modules/sign-up/sign-up.module";
import {SignInModule} from "./modules/sign-in/sign-in.module";
import {CommonDoaToaModule} from "../../../doatoa-common/common-doatoa.module";
import {ConsentPageModule} from "./modules/consent-page/consent-page.module";
import {AppRoutingModule} from "./app-routing/app-routing.module";
import {ChangePasswordModule} from "./modules/change-password/change-password.module";

const zxvbnConfig: ZxvbnConfigType = {
    translations: translations,
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        ErrorModule,
        LandingModule,
        ChangePasswordModule,
        SignUpModule,
        AppRoutingModule,
        SignInModule,
        TextFieldModule,
        ConsentPageModule,
        CommonDoaToaModule
    ],
    providers: [CacheInterceptor, {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheInterceptor,
        multi: true
    }, RequestAuthorizationInterceptor, {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestAuthorizationInterceptor,
        multi: true
    }, ResponseInterceptor, {
        provide: HTTP_INTERCEPTORS,
        useClass: ResponseInterceptor,
        multi: true
    }, HttpClientModule, provideZxvbnServiceForPSM(zxvbnConfig), DataService],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
