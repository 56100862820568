import {Component, Input, OnInit} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
    selector: 'app-logo',
    standalone: true,
    templateUrl: './logo.component.html',
    imports: [
        NgOptimizedImage
    ],
    styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

    @Input() width: string = '0'
    @Input() height: string = '0'
    @Input() classes: string = ''
    @Input() image: string = ''

    constructor() {
    }

    ngOnInit(): void {
    }

}
