import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CacheService} from "./cache-service";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(private cacheService: CacheService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method == 'get' || this.cacheService.bypassCache) {
            return next.handle(request).pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        this.cacheService.setData(request.url, event.body);
                    }
                })
            );
        }
        const cachedData = this.cacheService.getData(request.url);
        if (cachedData) {
            return of(new HttpResponse({ body: cachedData }));
        }
        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    this.cacheService.setData(request.url, event.body);
                }
            })
        );
    }
}
