<div class="container">
    <div *ngIf="!errorOccurred" class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'../../../assets/img/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="card max-width-400 text-center">
            <div class="p-2">
                <p>Your account was successfully activated, you can now sign in.</p>
                <app-generic-button
                        routerLink="/sign-in"
                        [buttonText]="'To sign-in page.'"
                        [icon]="'fa-solid fa-arrow-left'"></app-generic-button>
            </div>
        </div>
    </div>
    <app-error-modal [showModal]="errorOccurred" [message]="message"></app-error-modal>
</div>