<div class="container">
    <form (submit)="createClient()"
          autocomplete="off"
          class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'../../../assets/img/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="col-auto text-center card max-width-300 p-3">
            <h2>Sign up</h2>
            <div style="position: relative;">
            <input [(ngModel)]="email"
                   class="rounded-5 form-control mb-3 {{email == undefined || email == '' ? '' : email != undefined && isValidEmail(email) ? 'is-valid' : 'is-invalid'}}"
                   datatype="email"
                   id="email"
                   name="email"
                   placeholder="E-mail address"
                   type="email"
                   autocomplete="new-email">
            <i class="btn secondary-blue fa fa-circle-info p-1" style="position: absolute; right: 10px; top: 35%; transform: translateY(-50%);" (click)="flipShowEmailInfo()"></i>
        </div>
            <div class="p-3" *ngIf="showEmailInfo">The mandatory e-mail address used for all DoaToa correspondence.</div>
            <div style="position: relative;">
                <input [(ngModel)]="username"
                       class="rounded-5 form-control mb-3 {{resolveUsernameClass()}}"
                       datatype="text"
                       id="username"
                       name="username"
                       placeholder="Username"
                       (change)="validateUsername()"
                       type="text"
                       autocomplete="new-username">
                <i class="btn secondary-blue fa fa-circle-info p-1" style="position: absolute; right: 10px; top: 35%; transform: translateY(-50%);" (click)="flipShowUsernameInfo()"></i>
            </div>
            <div class="p-3" *ngIf="showUsernameInfo">Your unique (case-sensitive) identifier throughout DoaToa.</div>
            <input [(ngModel)]="password"
                   class="rounded-5 form-control mb-3 {{password == '' ? '' : password!.length < 15 ? 'is-invalid' : 'is-valid'}}"
                   datatype="password"
                   id="password"
                   name="password"
                   placeholder="Password (15 characters or more)"
                   type="password"
                   autocomplete="new-password">
            <password-strength-meter [password]="password" enableFeedback/>
            <div class="form-check rounded-5 col-auto">
                <label for="termsAndConditionsAccepted">I accept the
                    <a href="https://doatoa.com/terms-of-service/" target=”_blank”>terms and conditions.</a>
                </label>
                <input [checked]="termsAndConditionsAccepted"
                       (change)="switchTermsAndConditionsAccepted()"
                       class="form-check-input"
                       id="termsAndConditionsAccepted"
                       type="checkbox">
            </div>
            <div>
                <div class="mb-2"></div>
                <app-generic-button
                        [widthClass]="''"
                        [type]="'submit'"
                        [disabled]="!termsAndConditionsAccepted || loading || !isValidEmail(email) || password == '' || password == undefined || password.length < 15 || username == '' || username == undefined"
                        [loading]="loading"
                        [buttonText]="'Sign up'"
                        [icon]="'fa fa-identity-plus'"></app-generic-button>
            </div>
        </div>
    </form>
    <div class="m-3"></div>
    <div class="row justify-content-center">
        <div class="max-width-300 card text-center">
            <div class="p-2">
                Already have an account?
                <a class="secondary-blue"
                   routerLink="/sign-in">Sign in
                </a>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-auto m-auto text-center">
            <app-error-modal [message]="'This username is already taken'"
                             [showModal]="!!usernameIsTaken && usernameIsTaken"
                             [extraClasses]="'max-width-300 m-3'"></app-error-modal>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="max-width-400 text-center">
            <app-error-modal [showModal]="errorOccurred"
                             [extraClasses]="'m-3'"
                             [message]="errorMessage"></app-error-modal>
        </div>
    </div>
</div>