/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export namespace Requests {
    export interface RequestPayload {
        scopes: Array<string>
        requestor?: string;
        duration?: number;
        durationUnit?: Requests.DurationUnitEnum;
        startingMoment?: string;
        description?: string;
    }

    export type DurationUnitEnum = 'minutes' | 'hours' | 'days' | 'months' | 'years';
    export const DurationUnitEnum = {
        Minutes: 'minutes' as DurationUnitEnum,
        Hours: 'hours' as DurationUnitEnum,
        Days: 'days' as DurationUnitEnum,
        Months: 'months' as DurationUnitEnum,
        Years: 'years' as DurationUnitEnum
    };
}


