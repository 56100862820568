import {Component, Input} from '@angular/core';
import {ErrorModalComponent} from "../../../../../doatoa-common/error-modal/error-modal.component";
import {LogoComponent} from "../../../../../doatoa-common/logo/logo.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-success-landing',
  templateUrl: './success-landing.component.html',
  styleUrl: './success-landing.component.scss'
})
export class SuccessLandingComponent {
    @Input() message: string = ''
    @Input() loading: boolean = false
    @Input() errorOccurred: boolean = false
}
