import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {NgIf} from "@angular/common";
import {ErrorMessageService} from "../services/error-message-service";

@Component({
    selector: 'app-error-modal',
    standalone: true,
    templateUrl: './error-modal.component.html',
    imports: [
        NgIf
    ],
    styleUrl: './error-modal.component.scss'
})
export class ErrorModalComponent implements OnChanges, OnDestroy {

    @Input() extraClasses: string = ''
    @Input() message: string = ''
    @Input() showModal: boolean = false
    @Output() modalFinished: EventEmitter<any> = new EventEmitter()
    @Input() useTimout: boolean = true

    constructor(private errorMessageService: ErrorMessageService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['showModal'].currentValue as boolean) {
            setTimeout(() => {
                this.modalFinished.emit();
                this.showModal = false
            }, 5000);
        }
    }

    ngOnDestroy(): void {
        this.showModal = false;
    }

    retrieveMessage() {
        return this.errorMessageService.getMessage()
    }
}
