<button class="btn d-flex align-items-center justify-content-center {{widthClass}} height-40 {{btnClass}} {{disabled ? 'disabled' : ''}} p-1 rounded-5 container"
        [attr.data-bs-dismiss]="dataBsDismiss"
        [attr.type]="type"
        style="position: relative;">

    <div style="display: flex; align-items: center; position: absolute; left: 4px;">
        <app-spinner [loading]="loading"></app-spinner>
    </div>

    <div style="display: flex; align-items: center; margin-left: 20px;">
        <span class="text-center">{{ buttonText }}</span>
        <div style="margin-left: 8px; padding-top: 4px">
            <i class="{{ icon }}"></i>
        </div>
    </div>
</button>
