import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ErrorMessageService {

    private message?: string

    setMessage(message?: string) {
        this.message = message || "Oops, something went wrong, please try again."
    }

    getMessage(): string {
        return this.message || "Oops, something went wrong, please try again."
    }
}