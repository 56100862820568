/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {LicenseDetailsView} from "./license-details-view";
import LicenseNameEnum = LicenseDetailsView.LicenseNameEnum;


export interface PaymentRequestView {
    requestDate?: string;
    paidOn?: string;
    isDue?: boolean;
    dueDate?: string;
    overdue?: boolean;
    currency?: string;
    amount?: string;
    licenseName?: LicenseNameEnum;
    id?: string;
    status?: PaymentRequestView.StatusEnum;
    referenceId?: string;
    checkoutUrl?: string;
}

export namespace PaymentRequestView {
    export type StatusEnum = 'open' | 'canceled' | 'pending' | 'authorized' | 'expired' | 'failed' | 'paid';
    export const StatusEnum = {
        open: 'open' as StatusEnum,
        canceled: 'canceled' as StatusEnum,
        pending: 'pending' as StatusEnum,
        authorized: 'authorized' as StatusEnum,
        expired: 'expired' as StatusEnum,
        failed: 'failed' as StatusEnum,
        paid: 'paid' as StatusEnum
    };
}



