import {Component, OnInit} from '@angular/core';
import {CredentialsPayload} from "../../../../../doatoa-common/services";
import {ActivatedRoute, Router} from '@angular/router';
import {OauthQueryControllerService} from "../../../../../doatoa-common/services/api/oauth-query-controller-service";
import {
    OauthCommandControllerService
} from "../../../../../doatoa-common/services/api/oauth-command-controller-service";
import {AuthorizationRequestView} from "../../../../../doatoa-common/services/model/authorization-request-view";
import {RedirectService} from "../../../../../doatoa-common/services/redirect-service";
import {AuthorizationRequestParams} from "../../../../../doatoa-common/services/model/authorization-request-params";
import {DataService} from "../../../../../doatoa-common/services/data.service";
import {environment} from "../../../../../doatoa-common/environments/environment";

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
    username?: string = undefined;
    password?: string = undefined;
    code?: string = undefined;
    loading: boolean = false;
    twoStepAuthenticationEnabled: boolean = false;
    preLoading: boolean = true;
    errorOccurred: boolean = false
    errorMessage: string = ''
    queryParams?: AuthorizationRequestParams = undefined
    authorizationRequest?: AuthorizationRequestView = undefined

    constructor(private redirectService: RedirectService, private authCommandControllerService: OauthCommandControllerService, private oauthQueryControllerService: OauthQueryControllerService, private oauthCommandControllerService: OauthCommandControllerService, private activatedRoute: ActivatedRoute, private router: Router, private dataService: DataService) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            this.queryParams = p.params
            if (this.queryParams?.client_id == undefined) {
                //if no client_id is in place we assume people want to sign-in into DoaToa itself, so we populate all query parameters through this redirect
                this.redirectService.redirectToResourceWebapp()
            } else {
                this.preLoading = false
                setTimeout(() => {
                    this.redirectService.redirectToGateway()
                }, 240000);
            }
        });
    }

    authenticate() {
        this.loading = true
        let credentialsPayload: CredentialsPayload = {username: this.username, password: this.password}
        if (this.authorizationRequest == undefined) {
            this.oauthQueryControllerService.requestAuthorizationCode(this.queryParams)
                .subscribe({
                    next: (response) => {
                        this.authorizationRequest = response
                        this.authCommandControllerService.signIn(credentialsPayload, this.queryParams)
                            .subscribe({
                                next: (response) => {
                                    this.dataService.setNonDurable('username', credentialsPayload.username!)
                                    this.twoStepAuthenticationEnabled = response.twoStepAuthenticationEnabled!
                                    if (!this.twoStepAuthenticationEnabled) {
                                        this.continueAuthorizationRequest()
                                    }
                                    this.loading = false
                                },
                                error: (error) => {
                                    this.errorOccurred = true
                                    setTimeout(() => {
                                        if (this.queryParams?.client_id == environment.resourceWebappClientId) {
                                            this.redirectService.redirectToResourceWebapp()
                                        }
                                        else window.location.href = this.authorizationRequest!!.redirectUri!!
                                    }, 5000);
                                }
                            });
                    },
                    error: (error) => {
                        this.errorOccurred = true
                        setTimeout(() => {
                            this.redirectService.redirectToResourceWebapp()
                        }, 5000);
                    }
                });
        }

    }

    continueAuthorizationRequest() {
        if (this.authorizationRequest?.approved) {
            this.oauthCommandControllerService.process(true, this.authorizationRequest?.state!)
                .subscribe({
                    next: (response) => {
                        window.location.href = decodeURIComponent(response!.redirectUri!)
                        this.loading = false
                    },
                    error: (error) => {
                        this.errorOccurred = true
                        setTimeout(() => {
                            this.redirectService.redirectToResourceWebapp()
                        }, 5000);
                    }
                });
        } else {
            this.router.navigate(['/consent'], {
                queryParams: {
                    state: this.authorizationRequest?.state,
                    scopes: this.authorizationRequest?.openIdScopes,
                    client_name: this.authorizationRequest?.client?.name
                }
            })
        }
    }


    verifyCode() {
        this.authCommandControllerService.process(undefined, this.authorizationRequest!.state, this.code!)
            .subscribe({
                next: (response) => {
                    this.continueAuthorizationRequest()
                },
                error: (error) => {
                    this.errorOccurred = true
                    setTimeout(() => {
                        this.redirectService.redirectToResourceWebapp()
                    }, 5000);
                }
            });
    }
}
