import {Component, Input} from '@angular/core';
import {SpinnerComponent} from "../spinner/spinner.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-generic-button',
  standalone: true,
  templateUrl: './generic-button.component.html',
  imports: [
    SpinnerComponent,
    NgIf
  ],
  styleUrl: './generic-button.component.scss'
})
export class GenericButtonComponent {

  @Input() loading: boolean = false
  @Input() disabled: boolean = false
  @Input() widthClass: string = 'min-width-300'
  @Input() buttonText: string = ''
  @Input() btnClass: string = 'btn-basic-info'
  @Input() icon: string = ''
  @Input() type: string = ''
  @Input() dataBsDismiss: string = ''
}
