/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type LanguageEnum = 'NL' | 'EN';
export const LanguageEnum = {
    NL: 'NL' as LanguageEnum,
    EN: 'EN' as LanguageEnum
};

export interface IdentityPayload {
    emailAddress?: string;
    username?: string;
    password?: string;
    language?: LanguageEnum;
}



