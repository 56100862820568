/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {FactClaimPayload} from '../model/fact-claim-payload';
// @ts-ignore
import {FactView} from '../model/fact-view';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';
import {environment} from "../../environments/environment";
import {TokenSet} from "../model/token-set";
import {AuthorizationRequestView} from "../model/authorization-request-view";
import {DataService} from "../data.service";
import {CredentialsPayload} from "../model/credentials-payload";
import {AuthenticationResultView} from "../model/authentication-result-view";


@Injectable({
    providedIn: 'root'
})
export class OauthCommandControllerService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    protected basePath = environment.resourceApiUri;

    constructor(protected dataService: DataService, protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param credentialsPayload
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signIn(credentialsPayload: CredentialsPayload, params: any, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<AuthenticationResultView>;

    public signIn(credentialsPayload: CredentialsPayload, params: any, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpResponse<AuthenticationResultView>>;

    public signIn(credentialsPayload: CredentialsPayload, params: any, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpEvent<AuthenticationResultView>>;

    public signIn(credentialsPayload: CredentialsPayload, params: any, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any> {
        if (credentialsPayload === null || credentialsPayload === undefined) {
            throw new Error('Required parameter credentialsPayload was null or undefined when calling createSession.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }
        const state = params.state
        let localVarPath = `/oauth2/sign-in/?state=${state}&client_id=${environment.resourceWebappClientId}`;
        return this.httpClient.request<AuthenticationResultView>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: credentialsPayload,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param credentialsPayload
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshToken(refreshToken: string, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<TokenSet>;

    public refreshToken(refreshToken: string, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpResponse<TokenSet>>;

    public refreshToken(refreshToken: string, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpEvent<TokenSet>>;

    public refreshToken(refreshToken: string, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        localVarHeaders = localVarHeaders.set('Content-Type', "application/x-www-form-urlencoded");

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }
        const clientId = environment.resourceWebappClientId

        const httpParams: HttpParams = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('client_id', clientId)
            .set('refresh_token', refreshToken);
        let localVarPath = `/oauth2/tokens/`;
        return this.httpClient.request<TokenSet>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: httpParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signOut(idToken: string, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any>;

    public signOut(idToken: string, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpResponse<any>>;

    public signOut(idToken: string, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpEvent<any>>;

    public signOut(idToken: string, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }
        let localVarPath = `/oauth2/sign-out/?id_token_hint=` + idToken!;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Add a publicly claimable fact.
     * Please note, these facts will always be public and can be claimed by anyone.
     * @param factPayload
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public process(consentGiven?: boolean, id?: string, code?: string, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: '*/*',
        context?: HttpContext
    }): Observable<AuthorizationRequestView>;

    public process(consentGiven?: boolean, id?: string,code?: string, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: '*/*',
        context?: HttpContext
    }): Observable<HttpResponse<AuthorizationRequestView>>;

    public process(consentGiven?: boolean, id?: string,code?: string, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: '*/*',
        context?: HttpContext
    }): Observable<HttpEvent<AuthorizationRequestView>>;

    public process(consentGiven?: boolean, id?: string, code?: string, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: '*/*',
        context?: HttpContext
    }): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }
        let localVarPath = `/oauth2/authorization-requests/${id}`
        return this.httpClient.request<AuthorizationRequestView>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: {code: code, approved: consentGiven},
                responseType: 'json',
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public exchangeAuthorizationCodeForToken(code: string, clientId?: string, redirectUri?: string, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<TokenSet>;

    public exchangeAuthorizationCodeForToken(code: string, clientId?: string, redirectUri?: string, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<HttpResponse<TokenSet>>;

    public exchangeAuthorizationCodeForToken(code: string, clientId?: string, redirectUri?: string, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<HttpEvent<TokenSet>>;

    public exchangeAuthorizationCodeForToken(code: string, clientId?: string, redirectUri?: string, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        localVarHeaders = localVarHeaders.set('Content-Type', "application/x-www-form-urlencoded");
        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }
        const grantType = "authorization_code"
        const usedClientId: string = clientId || environment.resourceWebappClientId
        const responseType = 'query'
        const actualRedirectUri = redirectUri || environment.resourceWebappUri + '/#/oauth-landing'
        const actualCodeVerifier = this.dataService.get('code_verifier') as string
        const httpParams: HttpParams = new HttpParams()
            .set('grant_type', grantType)
            .set('code', code)
            .set('client_id', usedClientId)
            .set('response_type', responseType)
            .set('redirect_uri', actualRedirectUri)
            .set('code_verifier', actualCodeVerifier);
        let localVarPath = `/oauth2/tokens/`;
        return this.httpClient.request<TokenSet>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: httpParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                    throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

}
