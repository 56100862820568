import {Component, OnInit} from '@angular/core';
import {
    IdentityCommandControllerService,
    IdentityPayload,
    IdentityQueryControllerService, LanguageEnum
} from "../../../../../doatoa-common/services";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorMessageService} from "../../../../../doatoa-common/services/error-message-service";

@Component({
    selector: 'app-new-license',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

    termsAndConditionsAccepted: boolean = false
    email?: string = undefined;
    username?: string = undefined;
    password: string = '';
    loading: boolean = false;
    showEmailInfo: boolean = false;
    showUsernameInfo: boolean = false;
    noEmail: boolean = false;
    errorOccurred: boolean = false
    errorMessage: string = ''
    usernameIsTaken?: boolean = undefined
    hide = true;

    toggleVisibility(): void {
        this.hide = !this.hide;
    }
    constructor(private clientCommandControllerService: IdentityCommandControllerService, private clientQueryControllerService: IdentityQueryControllerService, private router: Router) {
    }

    validateUsername() {
        if (this.username) {
            this.clientQueryControllerService.gatherMetadata(this.username!)
                .subscribe({
                    next: (response) => {
                        this.usernameIsTaken = response.usernameTaken!
                        if (this.usernameIsTaken) {
                            document.getElementById('username')?.classList.add('is-invalid');
                            setTimeout(() => {
                                this.username = undefined
                                this.usernameIsTaken = false;
                                document.getElementById('username')?.classList.remove('is-invalid');
                            }, 5000);
                        }
                    },
                    error: (error) => {
                        if (error instanceof HttpErrorResponse) {
                            this.errorOccurred = true
                        }
                    }
                });
        }
    }

    createClient() {
        this.loading = true
        let userPayload: IdentityPayload = {
            emailAddress: this.email,
            username: this.username,
            password: this.password,
            language: LanguageEnum.EN
        }
        this.clientCommandControllerService.register(userPayload)
            .subscribe({
                next: (response) => {
                    this.router.navigate(['/registered-landing'])
                    this.loading = false
                    this.noEmail = this.email == undefined;
                    this.username = undefined;
                    this.password = '';
                },
                error: (error) => {
                    this.errorOccurred = true
                    this.loading = false
                    this.noEmail = this.email == undefined;
                    this.username = undefined;
                    this.password = '';
                }
            });
    }

    ngOnInit(): void {
    }

    isValidEmail = (email?: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    resolveUsernameClass(): string {
        if (this.username == '' || this.username == undefined)
            return ''
        if (this.usernameIsTaken || !String(this.username)?.match(/^[a-zA-Z0-9_-]+$/))
            return 'is-invalid'
        return 'is-valid'
    }

    switchTermsAndConditionsAccepted() {
        this.termsAndConditionsAccepted = !this.termsAndConditionsAccepted
    }

    flipShowEmailInfo() {
        this.showEmailInfo = !this.showEmailInfo
    }
    flipShowUsernameInfo() {
        this.showUsernameInfo = !this.showUsernameInfo
    }
}
