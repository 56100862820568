import {NgModule} from '@angular/core';
import {ConsentPage} from "./consent-page";
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";


@NgModule({
    declarations: [ConsentPage],
    imports: [
        CommonDoaToaModule
    ]
})
export class ConsentPageModule {
}
