/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LicenseDetailsView {
    name?: LicenseDetailsView.LicenseNameEnum;
    price?: string;
    currency?: string;
}

export namespace LicenseDetailsView {
    export type LicenseNameEnum =
        'freemium'
        | 'monthly_premium'
        | 'yearly_premium'
        | 'non_paying_premium'
        | 'admin';
    export const LicenseNameEnum = {
        freemium: 'freemium' as LicenseNameEnum,
        monthlyPremium: 'monthly_premium' as LicenseNameEnum,
        yearlyPremium: 'yearly_premium' as LicenseNameEnum,
        admin: 'admin' as LicenseNameEnum,
        nonPayingProfessional: 'non_paying_premium' as LicenseNameEnum
    };
}



