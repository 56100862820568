import {Component, OnInit} from '@angular/core';
import {LogoComponent} from "../../../../../../doatoa-common/logo/logo.component";
import {SpinnerComponent} from "../../../../../../doatoa-common/spinner/spinner.component";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../../../doatoa-common/environments/environment";
import {randomString} from "../../../../../../doatoa-common/services/random-strings";
import {RedirectService} from "../../../../../../doatoa-common/services/redirect-service";
import {
    OauthCommandControllerService
} from "../../../../../../doatoa-common/services/api/oauth-command-controller-service";
import {PKCEService} from "../../../../../../doatoa-common/services/PKCEService";
import {DataService} from "../../../../../../doatoa-common/services/data.service";

const TOKEN_LENGTH: number = 43;

@Component({
    selector: 'app-extension-landing',
    standalone: true,
    imports: [
        LogoComponent,
        SpinnerComponent
    ],
    templateUrl: './extension-landing.component.html',
    styleUrl: './extension-landing.component.scss'
})
export class ExtensionLandingComponent implements OnInit {

    protected message: string = 'Authorizing...';

    constructor(private activatedRoute: ActivatedRoute, private dataService: DataService, private pkceService: PKCEService, private redirectService: RedirectService, private oauthCommandControllerService: OauthCommandControllerService) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (p.params.origin) {
                const origin = p.params.origin as string
                this.dataService.setDurable('final-destination', origin)
                this.pkceService.createCodeChallenge().then(codeChallenge => {
                    this.dataService.setDurable('code_verifier', codeChallenge.code_verifier)
                    const queryParams = new URLSearchParams({
                        state: randomString(43),
                        grant_type: 'authorization_code',
                        client_id: environment.idpExtensionClientId,
                        response_type: 'code,id_token',
                        response_mode: 'query',
                        code_challenge: codeChallenge.code_challenge,
                        code_challenge_method: 'S265',
                        scope: 'api,openid,profile',
                        nonce: randomString(43),
                        redirect_uri: `${environment.idpWebappUri}/#/extension`,
                    });
                    this.redirectService.redirectToAuthWebappExtension(queryParams)
                })
            }
            if (p.params.code) {
                const origin = this.dataService.get('final-destination')
                this.oauthCommandControllerService.exchangeAuthorizationCodeForToken(p.params.code, environment.idpExtensionClientId, environment.idpWebappUri + '/#/extension')
                    .subscribe({
                        next: (response) => {
                            const idToken = response.id_token!
                            window.postMessage(idToken)
                            window.location.href = origin!
                        }
                    });
            }
        })
    }

}
