import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {catchError, map, Observable} from 'rxjs';
import {CacheService} from "./cache-service";
import {RedirectService} from "./redirect-service";
import {ErrorMessageService} from "./error-message-service";
import {DataService} from "./data.service";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(private redirectService: RedirectService, private errorMessageService: ErrorMessageService, private cacheService: CacheService, private dataService: DataService) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: any) => {
                if (event instanceof HttpResponse) {
                    this.cacheService.bypassCache = false
                    return event
                }
                return event
            }),
            catchError((response: any) => {
                if (response instanceof HttpErrorResponse) {
                    this.cacheService.bypassCache = false
                    if (response.status === 401) {
                        this.dataService.clear()
                        this.redirectService.redirectToAuthWebapp()
                    }
                    if (response.status === 403) {
                        this.errorMessageService.setMessage(response?.error?.message || "You are not allowed to take this action.")
                    } else {
                        this.errorMessageService.setMessage(response?.error?.message)
                    }
                }
                throw response
            })
        );
    }

}
