import {OauthCommandControllerService} from "./oauth-command-controller-service";

export * from './claim-command-controller.service';

import {ClaimCommandControllerService} from './claim-command-controller.service';
import {ClaimQueryControllerService} from './claim-query-controller.service';
import {IdentityCommandControllerService} from './identity-command-controller.service';
import {IdentityQueryControllerService} from './identity-query-controller.service';
import {FactCommandControllerService} from './fact-command-controller.service';
import {FactQueryControllerService} from './fact-query-controller.service';
import {RequestCommandControllerService} from './request-command-controller.service';
import {RequestQueryControllerService} from './request-query-controller.service';
import {OauthQueryControllerService} from "./oauth-query-controller-service";

export * from './claim-query-controller.service';

export * from './identity-command-controller.service';


export * from './identity-command-controller.service';
export * from './identity-query-controller.service';

export * from './fact-command-controller.service';
export * from './fact-query-controller.service';


export * from './request-command-controller.service';

export * from './request-query-controller.service';
export const APIS = [ClaimCommandControllerService, ClaimQueryControllerService, IdentityCommandControllerService, IdentityQueryControllerService, FactCommandControllerService, FactQueryControllerService, RequestCommandControllerService, RequestQueryControllerService, OauthCommandControllerService, OauthQueryControllerService];
