<div *ngIf="!loading"
     class="container">
    <form (submit)="changePassword()"
          class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-5'"
                  [height]="'200'"
                  [image]="'assets/img/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="max-width-300 card p-3">
            <h2 >Change your password</h2>
            <div>
                <label for="password"
                       >New password:</label>
                <input [(ngModel)]="newPassword"
                       class="rounded-5 form-control mb-3 {{newPassword == '' || newPassword.length < 15 ? 'is-invalid' : 'is-valid'}}"
                       datatype="password"
                       id="password"
                       name="password"
                       placeholder="Enter new password"
                       required
                       type="password">
                <password-strength-meter [password]="newPassword"
                                         enableFeedback/>
            </div>
            <div class="mb-2"></div>
            <app-generic-button
                    class="mb-2"
                    [widthClass]="''"
                    [type]="'submit'"
                    [disabled]="loading || newPassword == '' || newPassword == undefined || newPassword.length < 15"
                    [loading]="loading"
                    [buttonText]="'Change password'"
                    [icon]="'fa-solid fa-unlock-keyhole'"></app-generic-button>
            <app-generic-button
                    (click)="redirectToMyIdentity()"
                    [widthClass]="''"
                    [type]="'submit'"
                    [disabled]="loading"
                    [loading]="loading"
                    [buttonText]="'Back'"
                    [icon]="'fa-solid fa-arrow-left'"></app-generic-button>
        </div>
    </form>
</div>

