import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {mergeMap, Observable, retryWhen, timer,} from "rxjs";
import {DataService} from "./data.service";


@Injectable()
export class RequestAuthorizationInterceptor implements HttpInterceptor {

    constructor(protected dataService: DataService) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.dataService.get('accessToken');
        if (!request.url.includes('/oauth2/') && !request.headers.has('Authorization')) {
            request = this.addToken(request, token)
        }
        return next.handle(request).pipe(
            retryWhen(errors =>
                errors.pipe(
                    mergeMap((error: any, count: number) => {
                        if (count < 3 && this.shouldRetry(error)) {
                            return timer(3000);
                        }
                        throw error
                    })
                )
            )
        );

    }

    private shouldRetry(error: HttpErrorResponse): boolean {
        return error.status > 500 || error.status == 409
    }

    private addToken(request: HttpRequest<any>, token: any): HttpRequest<any> {
        if (token && !request.headers.has('Authorization')) {
            request = request.clone({headers: request.headers.set('Authorization', `Bearer ${token}`)});
        }
        return request;
    }

}