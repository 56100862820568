import {NgModule} from "@angular/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {ImageCropperModule} from "ngx-image-cropper";
import {ToOppositeVisibilityScopeIconPipe} from "./pipes/ToOppositeClaimAspectIconPipe";
import {ToDateTimeFormatPipe} from "./pipes/ToDateTimeFormatPipe";
import {ToVisibilityScopeIconPipe} from "./pipes/ToVisibilityScopeIconPipe";
import {ToReadableClaimAspectPipe} from "./pipes/ToReadableClaimAspectPipe";
import {ToBase64ContentTypePipe} from "./pipes/ToBase64ContentTypePipe";
import {ToClaimScopeIconPipe} from "./pipes/ToClaimTypeIconPipe";
import {ToReadableStringPipe} from "./pipes/ToReadableStringPipe";
import {ToReadableRequestStatusPipe} from "./pipes/ToReadableRequestStatusPipe";
import {ToDateFormatPipe} from "./pipes/ToDateFormatPipe";
import {ToFilteredClaimTypesPipe} from "./pipes/ToFilteredClaimTypesPipe";
import {ToLicenseNameWithFrequencyPipe} from "./pipes/ToLicenseNameWithFrequencyPipe";
import {ToCurrencySymbolPipe} from "./pipes/ToCurrencySymbolPipe";
import {ActionButtonComponent} from "./action-button/action-button.component";
import {ErrorModalComponent} from "./error-modal/error-modal.component";
import {FactComponent} from "./fact/fact.component";
import {GenericButtonComponent} from "./generic-button/generic-button.component";
import {LogoComponent} from "./logo/logo.component";
import {MenuComponent} from "./menu/menu.component";
import {SpinnerComponent} from "./spinner/spinner.component";
import {ClaimComponent} from "./claim/claim.component";
import {RequestComponent} from "./request/request.component";
import {DeleteClaimModalComponent} from "./delete-claim-modal/delete-claim-modal.component";
import {ExportModalComponent} from "./export-modal/export-modal.component";
import {NewRequestModalComponent} from "./new-request-modal/new-request-modal.component";
import {EventComponent} from "./event/event.component";
import {NewClaimModalComponent} from "./new-claim-modal/new-claim-modal.component";
import {GenericMessageComponent} from "./generic-message/generic-message.component";
import {ToStatusClassPipe} from "./pipes/ToStatusClassPipe";
import {LoadingPlaceholderComponent} from "./loading-placeholder/loading-placeholder.component";

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        NgOptimizedImage,
        ImageCropperModule,
        ReactiveFormsModule,
        SpinnerComponent,
        MenuComponent,
        LogoComponent,
        GenericButtonComponent,
        FactComponent,
        ErrorModalComponent,
        ActionButtonComponent,
        GenericMessageComponent
    ],
    exports: [
        LoadingPlaceholderComponent,
        SpinnerComponent,
        MenuComponent,
        LogoComponent,
        GenericButtonComponent,
        FactComponent,
        ErrorModalComponent,
        ActionButtonComponent,
        NewClaimModalComponent,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        NgOptimizedImage,
        CommonModule,
        ImageCropperModule,
        ReactiveFormsModule,
        ToReadableRequestStatusPipe,
        ToFilteredClaimTypesPipe,
        ToClaimScopeIconPipe,
        ToReadableStringPipe,
        ToVisibilityScopeIconPipe,
        ToOppositeVisibilityScopeIconPipe,
        ToReadableClaimAspectPipe,
        ToLicenseNameWithFrequencyPipe,
        ToCurrencySymbolPipe,
        ToStatusClassPipe,
        ToDateFormatPipe,
        ClaimComponent,
        ExportModalComponent,
        RequestComponent,
        GenericMessageComponent,
        NewRequestModalComponent
    ],
    declarations: [
        LoadingPlaceholderComponent,
        ToOppositeVisibilityScopeIconPipe,
        ToDateTimeFormatPipe,
        ToStatusClassPipe,
        ToVisibilityScopeIconPipe,
        ToReadableClaimAspectPipe,
        ToBase64ContentTypePipe,
        ToClaimScopeIconPipe,
        ToReadableRequestStatusPipe,
        ToDateFormatPipe,
        ToFilteredClaimTypesPipe,
        ToReadableStringPipe,
        ToLicenseNameWithFrequencyPipe,
        ToCurrencySymbolPipe,
        ClaimComponent,
        NewClaimModalComponent,
        RequestComponent,
        DeleteClaimModalComponent,
        ExportModalComponent,
        NewRequestModalComponent,
        EventComponent
    ]
})
export class CommonDoaToaModule { }