import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    private cache: { [key: string]: { data: any, timestamp: number } } = {};
    bypassCache: boolean = false;

    getData(key: string): any {
        const cachedData = this.cache[key];
        if (cachedData) {
            const currentTime = new Date().getTime();
            if (currentTime - cachedData.timestamp < 60000) {
                return cachedData.data;
            } else {
                delete this.cache[key];
            }
        }
        return null;
    }

    setData(key: string, data: any): void {
        this.cache[key] = {
            data,
            timestamp: new Date().getTime()
        };
    }

    clear(): void {
        this.cache = {};
    }
}
