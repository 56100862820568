import {Component, OnInit} from '@angular/core';
import {JsonPatchPayload, IdentityCommandControllerService} from "../../../../../doatoa-common/services";
import {ActivatedRoute, Router} from "@angular/router";
import {RedirectService} from "../../../../../doatoa-common/services/redirect-service";
import {DataService} from "../../../../../doatoa-common/services/data.service";

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    token?: string = undefined
    newPassword: string = ''
    loading: boolean = false
    errorOccurred: boolean = false

    constructor(private redirectService: RedirectService, private userCommandControllerService: IdentityCommandControllerService, private activatedRoute: ActivatedRoute, private router: Router, private dataService: DataService) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            this.token = p.params.token
            if (!!p.params.username) {
                this.dataService.setDurable('username', p.params.username)
            }
            if (!!p.params.token) {
                this.dataService.setDurable('accessToken', p.params.token)
            }
        })
    }

    changePassword() {
        this.loading = true
        const jsonPatchPayload: JsonPatchPayload = {
            op: "replace",
            path: "/password",
            value: this.newPassword
        }
        this.userCommandControllerService.patchUser([jsonPatchPayload])
            .subscribe({
                next: (response) => {
                    this.router.navigate(['/sign-in'])
                    this.loading = false
                }
            });
    }


    redirectToMyIdentity() {
        this.redirectService.redirectToMyIdentity()
    }
}