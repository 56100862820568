import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-password-landing',
  templateUrl: './password-landing.component.html',
  styleUrl: './password-landing.component.scss'
})
export class PasswordLandingComponent {

  @Input() message: string = ''
  @Input() loading: boolean = false
  @Input() errorOccurred: boolean = false
}
