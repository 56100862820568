import {NgModule} from '@angular/core';
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {ErrorComponent} from "./error.component";

@NgModule({
    imports: [
        CommonDoaToaModule
    ],
    declarations: [ErrorComponent]
})
export class ErrorModule { }