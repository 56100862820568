/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {FactView} from '../model/fact-view';

// @ts-ignore
import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';
import {environment} from "../../environments/environment";
import {ClaimReferenceView} from "../model/claim-reference-view";
import {AuthorizationRequestView} from "../model/authorization-request-view";

@Injectable({
    providedIn: 'root'
})
export class OauthQueryControllerService  {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    protected basePath = environment.resourceApiUri;

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    public requestAuthorizationCode(params: any, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<AuthorizationRequestView>;

    public requestAuthorizationCode(params: any, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<HttpResponse<AuthorizationRequestView>>;

    public requestAuthorizationCode(params: any, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<HttpEvent<AuthorizationRequestView>>;

    public requestAuthorizationCode(params: any, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        let localVarHeaders = this.defaultHeaders;

        const state = params.state
        const clientId = params.client_id
        const redirectUri = encodeURIComponent(params.redirect_uri)
        const nonce = params.nonce
        const scope = params.scope
        const responseType = params.response_type
        const codeChallenge = params.code_challenge
        const responseMode = params.response_mode

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/oauth2/authorization-codes/?state=${state}&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&response_mode=${responseMode}&scope=${scope}&nonce=${nonce}&code_challenge=${codeChallenge}`;
        return this.httpClient.request<AuthorizationRequestView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                    throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

}
