/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {ClaimReferenceView} from '../model/claim-reference-view';
// @ts-ignore
import {FactClaimPayload} from '../model/fact-claim-payload';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';
import {environment} from "../../environments/environment";
import {JsonPatchPayload} from "../model/json-patch-payload";
import {DataService} from "../data.service";


@Injectable({
    providedIn: 'root'
})
export class ClaimCommandControllerService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    protected basePath = environment.resourceApiUri;

    constructor(protected dataService: DataService, protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * Remove claim, the fact will only be removed when there are no other claimers.
     * @param claimId The id of the claim you wish to delete.
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteClaim(claimId: string, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any>;

    public deleteClaim(claimId: string, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpResponse<any>>;

    public deleteClaim(claimId: string, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpEvent<any>>;

    public deleteClaim(claimId: string, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any> {
        if (claimId === null || claimId === undefined) {
            throw new Error('Required parameter claimId was null or undefined when calling deleteClaim.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }
        let localVarPath = `/identities/claims/${this.configuration.encodeParam({
            name: "claimId",
            value: claimId,
            in: "path",
            style: "simple",
            explode: false,
            dataType: "string",
            dataFormat: "uuid"
        })}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Claim an existing fact, or create a new claim.
     * @param factClaimPayload
     * @param existingFactClaimPayload
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public makeClaim(claimOwner?: string, factClaimPayload?: FactClaimPayload, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<ClaimReferenceView>;

    public makeClaim(claimOwner?: string, factClaimPayload?: FactClaimPayload, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<HttpResponse<ClaimReferenceView>>;

    public makeClaim(claimOwner?: string, factClaimPayload?: FactClaimPayload, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<HttpEvent<ClaimReferenceView>>;

    public makeClaim(claimOwner?: string, factClaimPayload?: FactClaimPayload, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        localVarHeaders = localVarHeaders.set('Content-Type', 'application/json')

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }
        let localVarPath = `/identities/${claimOwner}/claims/`;
        return this.httpClient.request<ClaimReferenceView>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: factClaimPayload,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                    throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    public alterClaim(claimId: string, patchPayloads: Array<JsonPatchPayload>, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<ClaimReferenceView>;

    public alterClaim(claimId: string, patchPayloads: Array<JsonPatchPayload>, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<HttpResponse<ClaimReferenceView>>;

    public alterClaim(claimId: string, patchPayloads: Array<JsonPatchPayload>, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<HttpEvent<ClaimReferenceView>>;

    public alterClaim(claimId: string, patchPayloads: Array<JsonPatchPayload>, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: 'application/json',
        context?: HttpContext
    }): Observable<any> {
        let localVarQueryParameters = new HttpParams({encoder: this.encoder});

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }
        let localVarPath = `/identities/claims/${claimId}`;
        return this.httpClient.request<ClaimReferenceView>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: patchPayloads,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
