/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {VisibilityScopeEnum} from "./visibility-scope";


export interface FactClaimPayload {
    factId?: string;
    factValue?: string;
    factAspects?: Array<FactPayload.AspectsEnum>;
    claimType?: string;
    claimDescription?: string;
    claimVisibilityScope?: VisibilityScopeEnum;
    claimAspects?: Array<string>;
}

export namespace FactPayload {
    export type AspectsEnum =
        'public'
        | 'requestable'
        | 'single_view'
        | 'verified'
        | 'proposed'
        | 'single_claimer'
        | 'archived';
    export const AspectsEnum = {
        Public: 'public' as AspectsEnum,
        Requestable: 'requestable' as AspectsEnum,
        SingleView: 'single_view' as AspectsEnum,
        Verified: 'verified' as AspectsEnum,
        Proposed: 'proposed' as AspectsEnum,
        SingleClaimer: 'single_claimer' as AspectsEnum,
        Archived: 'archived' as AspectsEnum
    };
}


