import {Component, Input, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-spinner',
    standalone: true,
    templateUrl: './spinner.component.html',
    imports: [
        NgIf
    ],
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    @Input() loading: boolean = false
    @Input() spinnerClasses: string = ''
    @Input() style: string = 'width: 2rem; height: 2rem;'

    constructor() {
    }

    ngOnInit(): void {
    }

}
