/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {EventView} from './event-view';
import {ClaimView} from "./claim-reference-view";
import {EventEnum} from "./status";

export interface RequestView {
    id?: string;
    initiator?: string;
    description?: string;
    scopes?: Array<string>;
    startingOn?: Date;
    expiresOn?: Date;
    type?: RequestView.TypeEnum;
    status?: EventEnum;
    events?: Array<EventView>;
}

export namespace RequestView {
    export type TypeEnum = 'proposed_claim' | 'subscription' | 'claim_deletion';
    export const TypeEnum = {
        proposedClaim: 'proposed_claim' as TypeEnum,
        subscription: 'subscription' as TypeEnum,
        claimDeletion: 'claim_deletion' as TypeEnum
    };
}



