import {NgModule} from '@angular/core';
import {CommonDoaToaModule} from "../../../../../doatoa-common/common-doatoa.module";
import {RegisteredLandingComponent} from "./registered-landing.component";
import {SuccessLandingComponent} from "./success-landing.component";
import {PasswordLandingComponent} from "./password-landing.component";
import {ActivateUserInitComponent} from "./activate-user-init.component";
import {MaintenanceComponent} from "./maintenance/maintenance.component";

@NgModule({
    imports: [
        CommonDoaToaModule
    ],
    exports: [
        SuccessLandingComponent
    ],
    declarations: [MaintenanceComponent, RegisteredLandingComponent, SuccessLandingComponent, PasswordLandingComponent, ActivateUserInitComponent]
})
export class LandingModule { }