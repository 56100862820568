<div class="container" *ngIf="!preLoading">
    <form (submit)="authenticate()"
          autocomplete="off"
          class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'../../../assets/img/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="max-width-300 card p-3" *ngIf="!preLoading && twoStepAuthenticationEnabled">
            <h2 class="text-center">Enter the received 2FA code</h2>
            <input [(ngModel)]="code"
                   class="rounded-5 form-control mb-3"
                   datatype="text"
                   id="code"
                   name="code"
                   placeholder="123456"
                   type="text">
            <app-generic-button
                    (click)="verifyCode()"
                    id="submit-code"
                    [widthClass]="''"
                    [disabled]="loading || code == '' || code == undefined"
                    [loading]="loading"
                    [buttonText]="'Verify'"
                    [icon]="'fa-solid fa-right-to-bracket'">
            </app-generic-button>
            <div class="mb-3"></div>
        </div>
        <div class="max-width-300 card p-3" *ngIf="!preLoading && !twoStepAuthenticationEnabled">
            <h2 class="text-center">Sign in</h2>
            <div>
                <input [(ngModel)]="username"
                       class="rounded-5 mb-3 form-control"
                       datatype="text"
                       id="username"
                       name="username"
                       placeholder="Enter username"
                       type="text"
                       autocomplete="new-username">
            </div>
            <div>
                <input [(ngModel)]="password"
                       class="rounded-5 form-control"
                       datatype="password"
                       id="password"
                       name="password"
                       placeholder="Enter password"
                       type="password"
                       autocomplete="new-password">
            </div>
            <div class="mb-3"></div>
            <div>
                <app-generic-button
                        id="sign-in"
                        [widthClass]="''"
                        [type]="'submit'"
                        [disabled]="loading || password == '' || password == undefined || username == '' || username == undefined"
                        [loading]="loading"
                        [buttonText]="'Sign in'"
                        [icon]="'fa-solid fa-right-to-bracket'">
                </app-generic-button>
                <a class="float-end secondary-blue pt-2"
                   id="password-reset"
                   routerLink="/reset-password">I forgot my password...
                </a>
            </div>
        </div>
    </form>
    <div class="m-3"></div>
    <div class="row justify-content-center">
        <div class="max-width-300 card text-center">
            <div class="p-2">New to DoaToa?
                <a routerLink="/sign-up">Sign up
                </a>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="max-width-400 text-center">
            <app-error-modal [showModal]="errorOccurred"
                             [extraClasses]="'m-3'"
                             [message]="errorMessage"></app-error-modal>
        </div>
    </div>
    <div class="row justify-content-center">
        <img class="max-width-400 mx-auto" alt="sign-in" ngSrc="assets/img/sign-in.png" height="400" width="400">
    </div>
</div>

