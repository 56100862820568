import {Injectable} from '@angular/core';
import {CacheService} from "./cache-service";

@Injectable()
export class DataService {
    
    private nonDurableSessionStorage: Map<string, string> = new Map<string, string>();
    constructor(private cacheService: CacheService) { }

    setDurable(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    setNonDurable(key: string, data: any) {
        this.nonDurableSessionStorage.set(key, data);
    }

    get(name: string): any {
        if (this.has(name)) {
            if (this.nonDurableSessionStorage.has(name)) {
                return this.nonDurableSessionStorage.get(name);
            }
            let data = localStorage.getItem(name);
            if (data == undefined || data == 'undefined') {
                return undefined
            }
            return JSON.parse(data);
        }
        return null
    }
    
    has(name: string): boolean {
        return this.nonDurableSessionStorage.has(name) || localStorage.getItem(name) != null
    }

    remove(name: string) {
        if (this.nonDurableSessionStorage.has(name)) {
            this.nonDurableSessionStorage.delete(name);
        }
        localStorage.removeItem(name);
    }

    clear() {
        this.nonDurableSessionStorage.clear()
        this.cacheService.clear()
        localStorage.clear()
    }

}